<template>
    <div class="banHammer">
        <div class="flexible-block ">
            <div class="c-row-actions-container" style="margin-top: -20px; padding: 20px">
                <div class="c-row-actions">
                    Настал момент для справедливости? Здесь можно выдать наказание игроку на серверах, на которых Вы
                    являетесь модератором
                </div>
            </div>
            <div class="pretty-border"/>
            <div style="display: flex">
                <div style="border: 1px groove gray;margin: 5px;width: 300px">
                    <div class="banSearch">
                        <div class="reason-list-item" v-for="reason in reasonList" @click="addNewRule(reason.number)">
                            <b style="color: orangered">{{ reason.number }}. </b>
                            <span v-for="ruledata in reason.main" class="reason-list-item-rule">{{
                                    ruledata
                                }}<br></span>
                        </div>
                        <div v-show="reasonList.length === 0">
                            Здесь отобразятся правила,
                            <br>
                            введённые в причину
                        </div>
                    </div>
                </div>
                <div style="border: 1px groove gray;margin: 5px">
                    <div style="padding: 10px;display: flex">
                        <CustomSelect style="margin: 4px;" :class="{'invalid': invalid.server}"
                                      v-bind:options="getServers()" default="Выберите"
                                      @send="changeParam('server', getServersMapping(), $event)"/>
                        <CustomSelect style="margin: 4px;"
                                      :class="{'invalid': invalid.type, 'hidden': Object.keys(type_values).length <= 1}"
                                      v-bind:options="getOptions(type_values)" default="Выберите"
                                      @send="changeParam('type', type_values, $event)"/>
                    </div>
                    <div style="padding: 10px;display: flex">
                        <input class="filter-input" :class="{'invalid': invalid.player}" type="text"
                               v-model="ban.player" placeholder="Игрок" @keyup="liveSearchPlayer(ban.player, 5)">
                        <input class="filter-input" :class="{'invalid': invalid.reason}" type="text"
                               v-model="ban.reason" placeholder="Причина" @keyup="searchRule()">
                    </div>
                    <div style="padding: 10px;display: flex"
                         :class="{'hidden': ban.type === 'ban' || ban.type === 'kick'}">
                        <input class="filter-input" :class="{'invalid': invalid.amount}" type="text"
                               v-model="ban.amount" @keypress="onlyNumber"
                               placeholder="Количество">
                        <CustomSelect style="margin: 4px;" :class="{'invalid': invalid.unit}"
                                      v-bind:options="getOptions(dt_values)" default="Выберите"
                                      @send="changeParam('unit', dt_values, $event)"/>
                    </div>
                    <div style="padding: 10px">
                        <input class="filter-input" type="button" value="Применить" @click="pun()"
                               style="cursor: pointer;width: auto">
                    </div>
                </div>
                <div style="border: 1px groove gray;margin: 5px;width: 300px">
                    <div class="banSearch">
                        <div v-for="name in liveSearchList" @click="ban.player = name; liveSearchList = [name]">
                            <span class="b-p-live-search-name">{{ name }}</span>
                        </div>
                        <div v-show="liveSearchList.length === 0">
                            Начните вводить имя игрока,
                            <br>
                            чтобы увидеть варианты
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {TextInputControlMixin} from "@/mixins/TextInputControlMixin";
    import {PreloaderMixin} from "@/mixins/PreloaderMixin";
    import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
    import {PlayerLiveSearchMixin} from "@/mixins/PlayerLiveSearchMixin";
    import confirmModal from "@/components/modals/ConfirmModal";
    import CustomSelect from "@/components/elements/donat/CustomSelect";

    export default {
        mixins: [TextInputControlMixin, PreloaderMixin, TimeFormatterMixin, PlayerLiveSearchMixin],
        name: "banHammer",
        components: {CustomSelect},
        methods: {
            addNewRule(rule) {
                // TODO хз
            },
            searchRule() {
                let splitted = this.ban.reason.trim().replaceAll(',', '').split(' ')
                let reason_vars = {}
                splitted.forEach(data => {
                    this.rules.forEach(rule => {
                        if (rule.type === 'rule' && rule.number === data) {
                            if (reason_vars[data] === undefined)
                                reason_vars[data] = []
                            reason_vars[data].push(rule)
                        }
                    })
                })
                this.reasonList = []
                for (let key in reason_vars) {
                    if (reason_vars[key].length === 1)
                        this.reasonList.push(reason_vars[key][0])
                }
            },
            getServers() {
                let res = ['Выберите']
                if (this.$root.isAdmin()) {
                    for (var key in this.$root.getPlayerData().luckperms)
                        res.push(key)
                    return res
                }
                this.$root.getServersModerator().forEach(s => {
                    res.push(s.server)
                })
                return res
            },

            getServersMapping() {
                let res = {'Выберите': ''}
                if (this.$root.isAdmin()) {
                    for (var s in this.$root.getPlayerData().luckperms)
                        res[s] = s
                    return res
                }
                this.$root.getServersModerator().forEach(s => {
                    res[s.server] = s.server
                })
                return res
            },

            addAvailablePunTypes(s) {
                let check = {
                    'Выберите': '',
                    'Мут': 'mute',
                    'Тюрьма': 'jail',
                    'Кик с сервера': 'kick'
                }
                if (s.can_tempban || s.can_ban) {
                    check['Временный бан'] = 'tempban'
                }
                if (s.can_ban) {
                    check['Вечный бан'] = 'ban'
                }
                console.log(check)
                this.type_values = check
            },

            changeParam(key, mapping, res) {
                if (this.formatted[key] !== undefined)
                    this.formatted[key] = res
                if (mapping !== null)
                    res = mapping[res]
                this.ban[key] = res
                if (key === 'server') {
                    this.type_values = {
                        'Выберите': ''
                    }
                    if (this.$root.isAdmin()) {
                        this.addAvailablePunTypes({
                            server: res,
                            can_ban: true,
                            can_tempban: true
                        })
                    } else {
                        this.$root.getServersModerator().forEach(s => {
                            if (s.server === res) {
                                this.addAvailablePunTypes(s)
                            }
                        })
                    }
                }
            },

            getOptions(options) {
                let res = []
                for (var key in options)
                    res.push(key)
                return res
            },

            pun() {
                this.invalid.server = this.ban.server.length === 0
                this.invalid.player = this.ban.player.length === 0
                this.invalid.type = this.ban.type.length === 0
                this.invalid.reason = this.ban.reason.length === 0
                this.invalid.unit = this.ban.unit.length === 0 && this.ban.type !== 'ban' && this.ban.type !== 'kick'
                this.invalid.amount = this.ban.amount == 0 && this.ban.type !== 'ban' && this.ban.type !== 'kick'

                let check = true
                for (let key in this.invalid) {
                    if (this.invalid[key])
                        check = false
                }
                if (!check)
                    return
                let res = {
                    server_name: this.ban.server,
                    player: this.ban.player.trim(),
                    type: this.ban.type,
                    reason: this.ban.reason.trim(),
                    expiry: this.ban.type !== 'ban' && this.ban.type !== 'kick' ? this.dt_values_mults[this.ban.unit] * this.ban.amount : 0
                }

                this.$modal.show(confirmModal, {
                    "operation": 'pun',
                    "data": {
                        'pun': res,
                        'format': {
                            'time': this.ban.amount + ' ' + this.formatted.unit.toLowerCase(),
                            'type': this.formatted.type.toLowerCase()
                        }
                    },
                }, {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                    width: "420px",
                    height: "auto",
                    reset: true,
                })
            }
        },
        data() {
            return {
                formatted: {
                    unit: '',
                    type: ''
                },
                dt_values: {
                    'Выберите': '',
                    'Секунд': 'S',
                    'Минут': 'M',
                    'Часов': 'H',
                    'Дней': 'D'
                },

                dt_values_mults: {
                    'S': 1,
                    'M': 60,
                    'H': 3600,
                    'D': 86400,
                    '': 0,
                },
                invalid: {
                    type: false,
                    reason: false,
                    player: false,
                    server: false,
                    unit: false,
                    amount: false
                },
                ban: {
                    type: '',
                    reason: '',
                    player: '',
                    server: '',
                    unit: '',
                    amount: ''
                },

                type_values: {
                    'Выберите': ''
                },

                reasonList: [],
                rules: []
            }
        },
        mounted() {
            require('axios').get('/api/rules').then(data => {
                data.data.forEach(i => {
                    i.rules.forEach(rule => {
                        this.rules.push(rule)
                    })
                })
            })
        }
    }
</script>

<style scoped lang="scss">
    .filter-input {
        margin: 4px;
        background-color: #28272b;
        border-left: 5px solid #4f48b5;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
        border-radius: 6px;
        color: #fff;
        padding-left: 1em;
        padding-right: 1em;
        height: 40px;
        width: 168px;
    }

    .banSearch {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .b-p-live-search-name {
        border-radius: 10px;
        display: inline-block;
        padding: 6px;
        margin: 2px;
        background-color: rgb(89, 51, 194);
        color: white;
        cursor: pointer;
    }

    .invalid {
        border-radius: 6px;
        box-shadow: 0 0 6px red;
    }

    .hidden {
        opacity: 0.1
    }

    @import "src/assets/scss/animations";
    @import "src/assets/scss/mixins";
    @import "src/assets/scss/vars";

    .banHammer {
        @include flexible();
        @include text-1-0();
        color: $not-bright-white;
    }

    .pretty-border {
        @include smooth-border();
        margin: 0;
    }

    .flexible-block {
        width: 100%;
        @include pretty-actions();
    }

</style>