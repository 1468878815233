<template>
    <div class="banHammer">
        <div class="flexible-block ">
            <div class="c-row-actions-container" style="margin-top: -20px; padding: 20px">
                <div class="c-row-actions">
                    Нужно больше доказательств? Здесь можно почувствовать себя спецагентом и исследовать скриншоты
                    игроков
                </div>
            </div>
            <div class="pretty-border"/>
            <div style="display: flex">
                <div style="border: 1px groove gray;margin: 5px;width: 300px">
                    <div class="banSearch">
                        <div v-show="!loading && error === ''">
                            Всего скриншотов: {{ all }}
                            <br>
                            Страница: {{ nowPage }} / {{ pagesCount }}
                        </div>
                        <div v-show="!loading && error !== ''">
                            {{ error }}
                        </div>
                        <div v-show="loading">
                            Ждите,
                            <br>
                            Данные загружаются...
                        </div>
                    </div>
                </div>
                <div style="border: 1px groove gray;margin: 5px">
                    <div style="padding: 10px;display: flex">
                        <input class="filter-input" :class="{'invalid': invalid.player}" type="text"
                               v-model="player" placeholder="Игрок" @keyup="liveSearchPlayer(player, 5)">
                        <CustomSelect style="margin: 4px;" :class="{'invalid': invalid.server}"
                                      v-bind:options="getServers()" default="Выберите"
                                      @send="changeParam('server', getServersMapping(), $event)"/>
                    </div>
                    <div style="padding: 10px;display: flex">
                        <input class="filter-input" type="text"
                               v-tooltip="{content: 'Номер страницы', theme:'LkToolTips-top-withPd'}"
                               v-model="page" @keypress="onlyNumber"
                               placeholder="Страница">
                        <CustomSelect style="margin: 4px;"
                                      v-tooltip="{content: 'Ширина скриншотов', theme:'LkToolTips-top-withPd'}"
                                      :options="[300, 400, 500, 600, 1200]" default="300"
                                      @send="size=$event"/>
                    </div>
                    <div style="padding: 10px">
                        <input class="filter-input" type="button" value="Загрузить" @click="getScreens()"
                               style="cursor: pointer;width: auto">
                    </div>
                </div>
                <div style="border: 1px groove gray;margin: 5px;width: 300px">
                    <div class="banSearch">
                        <div v-for="name in liveSearchList" @click="player = name; liveSearchList = [name]">
                            <span class="b-p-live-search-name">{{ name }}</span>
                        </div>
                        <div v-show="liveSearchList.length === 0">
                            Начните вводить имя игрока,
                            <br>
                            чтобы увидеть варианты
                        </div>
                    </div>
                </div>
            </div>
            <div class="pretty-border"/>
            <div style="min-height: 600px">
                <div class="apreloader" v-show="loading"/>
                <div v-show="!loading">
                    <img class="screen" :style="`width: ${size}px`" @click="showModal({
                        'url': `https://api.greatray.ru/api/telemetr/public/screen/${screen.name}`,
                        'player': screen.player,
                        'server': screen.server,
                        'date': datetimeNormalizer(screen.created_at),
                        'id': screen.id
                    })"
                         v-tooltip="{content: datetimeNormalizer(screen.created_at) + ', ' + screen.player, theme:'LkToolTips-top-withPd'}"
                         v-for="screen in screens"
                         :src="`https://api.greatray.ru/api/telemetr/public/screen/${screen.name}`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {TextInputControlMixin} from "@/mixins/TextInputControlMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {PlayerLiveSearchMixin} from "@/mixins/PlayerLiveSearchMixin";
import CustomSelect from "@/components/elements/donat/CustomSelect";
import confirmModal from "@/components/modals/ConfirmModal";

export default {
    mixins: [TextInputControlMixin, PreloaderMixin, TimeFormatterMixin, PlayerLiveSearchMixin],
    name: "screens",
    components: {CustomSelect},
    methods: {
        getServers() {
            let res = ['Выберите']
            if (this.$root.isAdmin()) {
                for (var key in this.$root.getPlayerData().luckperms)
                    res.push(key)
                return res
            }
            this.$root.getServersModerator().forEach(s => {
                res.push(s.server)
            })
            return res
        },

        getServersMapping() {
            let res = {'Выберите': ''}
            if (this.$root.isAdmin()) {
                for (var s in this.$root.getPlayerData().luckperms)
                    res[s] = s
                return res
            }
            this.$root.getServersModerator().forEach(s => {
                res[s.server] = s.server
            })
            return res
        },

        showModal(data) {
            this.$modal.show(confirmModal, {
                "operation": 'screen',
                "data": data,
            }, {
                styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none;",
                width: "1280px",
                height: "auto",
                reset: true,
            })
        },

        changeParam(key, mapping, res) {
            if (mapping !== null)
                res = mapping[res]
            this.server = res
        },

        getPages() {
            let p = [1]
            for (var i = 2; i < this.pagesCount; i++)
                p.push(i)
            return p
        },

        getOptions(options) {
            let res = []
            for (var key in options)
                res.push(key)
            return res
        },

        getScreens() {
            this.invalid.server = this.server.length === 0
            this.invalid.player = this.player.length === 0

            let check = true
            for (let key in this.invalid) {
                if (this.invalid[key])
                    check = false
            }
            if (!check)
                return
            this.loading = true
            this.screens = null
            this.preloaderStartLk('.apreloader')
            let pl = this.player
            let srv = this.server

            require('axios').get(`/api/moder/screens/${this.player}/${this.server}/${this.page}`).then(data => {
                this.screens = []
                if(data.data.data === undefined) {
                    this.pagesCount = 0
                    this.nowPage = 1
                    this.all = 0
                    this.error = 'Ничего не нашлось!'
                }else {
                    data.data.data.forEach(s => {
                        if (s.user === undefined)
                            s.player = pl
                        else
                            s.player = s.user.name
                        if (s.server === undefined)
                            s.server = srv
                        this.screens.push(s)
                    })
                    this.pagesCount = data.data.last_page
                    this.nowPage = this.page
                    this.all = data.data.total
                    this.error = ''
                }
                this.preloaderStop('.apreloader')
                this.loading = false
            }).catch(err => {
                if (err.response.status === 401) {
                    this.error = 'Нет прав просматривать данного игрока на данном сервере!'
                    this.invalid.player = true
                    this.invalid.server = true
                } else if (err.response.status === 404) {
                    this.invalid.player = true
                    this.error = 'Игрок не найден!'
                } else {
                    this.error = 'Ошибка сервера!'
                }
                this.preloaderStop('.apreloader')
                this.loading = false
            })
        }
    },
    data() {
        return {
            invalid: {
                player: false,
                server: false,
            },
            error: '',
            server: '',
            player: '',
            screens: [],
            pagesCount: 0,
            page: 1,
            nowPage: 1,
            size: 300,
            all: 0,
            loading: false,
        }
    },
}
</script>

<style scoped lang="scss">
.filter-input {
    margin: 4px;
    background-color: #28272b;
    border-left: 5px solid #4f48b5;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 6px;
    color: #fff;
    padding-left: 1em;
    padding-right: 1em;
    height: 40px;
    width: 168px;
}

.banSearch {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.b-p-live-search-name {
    border-radius: 10px;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    background-color: rgb(89, 51, 194);
    color: white;
    cursor: pointer;
}

.invalid {
    border-radius: 6px;
    box-shadow: 0 0 6px red;
}

.hidden {
    opacity: 0.1
}

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.banHammer {
    @include flexible();
    @include text-1-0();
    color: $not-bright-white;
}

.pretty-border {
    @include smooth-border();
    margin: 0;
}

.flexible-block {
    width: 100%;
    @include pretty-actions();
}

.screen {
    background: black;
    margin: 5px;
    border-radius: 5px;
    display: inline-block;
}

</style>