<template>
    <div class="activity">
        Ув. Модерация. Данная вкладка пока не реализована. Следите за обновлениями.
    </div>
</template>

<script>
export default {
    name: "activity"
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.activity {
    @include flexible();
    @include text-1-0();
    color: $not-bright-white;
}

</style>