<template>
    <div v-if="$root.isModerator()" class="content">
        <div class="c">
            <div class="c-info-block">
                <h1 class="c-title">Панель модератора</h1>
                <p class="c-info-text">
                    С большей силой приходит и большая отвественность. <br><br>

                    Ничто не влияет на ваш способ мышления и чувства сильнее, чем люди, с которыми вы
                    взаимодействуете. Мысли, как позитивные, так и негативные, заразительны. Если вы окружены
                    негативными людьми, вполне вероятно, что вы начнёте думать и чувствовать, как и они. Чтобы
                    улучшить свой взгляд на жизнь, проводите время с позитивными людьми. Найдите их и попытайтесь
                    понять, как они смотрят на жизнь. Постарайтесь научиться мыслить позитивно как можно чаще.
                    <br><br>
                    Модератор - помни об ответственности, ты больше чем игрок проекта!
                </p>
            </div>

            <div class="c-row-actions-container">
                <div class="c-row-actions">
                    <ul>
                        <li :class="{'active_tab': moderActions_tabs.banList}"
                            @click="changeFrameCustom('banList', moderActions_tabs)">Бан лист
                        </li>
                        <li :class="{'active_tab': moderActions_tabs.banHammer}"
                            @click="changeFrameCustom('banHammer', moderActions_tabs)">Бан хаммер
                        </li>
                        <li :class="{'active_tab': moderActions_tabs.screens}"
                            @click="changeFrameCustom('screens', moderActions_tabs)">Скриншоты игроков
                        </li>
                    </ul>
                </div>
            </div>

            <div class="c-frame">

                <div class="al-actions-frame" v-if="moderActions_tabs.banList">
                    <ban-list/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.banHammer">
                    <ban-hammer/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.tasks">
                    <tasks/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.activity">
                    <activity/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.staff">
                    <staff/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.logs">
                    <logs/>
                </div>

                <div class="al-actions-frame" v-if="moderActions_tabs.screens">
                    <screens/>
                </div>


            </div>
        </div>
    </div>
    <div v-else>
        <NotFound/>
    </div>
</template>

<script>

import {TextInputControlMixin} from "@/mixins/TextInputControlMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";

import NotFound from "@/views/NotFound";
import banList from "@/components/elements/moder/banList";
import banHammer from "@/components/elements/moder/banHammer";
import tasks from "@/components/elements/moder/tasks";
import activity from "@/components/elements/moder/activity";
import staff from "@/components/elements/moder/staff";
import logs from "@/components/elements/moder/logs";
import screens from "@/components/elements/moder/screens";

export default {
    name: "Moder",
    components: {
        NotFound,
        banList,
        banHammer,
        tasks,
        activity,
        staff,
        logs,
        screens,
    },
    mixins: [TextInputControlMixin, PreloaderMixin, TimeFormatterMixin, ChangeTabMixin],
    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Панель модератора',
    },

    data() {
        return {
            moderActions_tabs: {
                banList: true,
                banHammer: false,
                tasks: false,
                activity: false,
                staff: false,
                logs: false
            }
        }
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.content {
    @include flexible();
    width: 100vw;

    .c {
        @include simple-ctrr();
        @include in-page-preloader();

        @include pretty-actions();
    }
}

@include ct-n-rp-transitions();
@include info-table-with-paginator();


</style>
