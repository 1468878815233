<template>
    <div class="tasks">
        Ув. Модерация. Данная вкладка пока не реализована. Следите за обновлениями.
    </div>
</template>

<script>
export default {
    name: "tasks"
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.tasks {
    @include flexible();
    @include text-1-0();
    color: $not-bright-white;
}

</style>